<template>
  <div>
    <div v-if="!claim" class="">
      <h5>Loading...</h5>
    </div>
    
    <div v-else>

      <!-- top header banner -->
      <div class="bg-site-light pt-4 mb-4">
        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-end py-2 text-center text-sm-left " >
                <!-- header -->
                <div class="flex-sm-fill">
                  <h2 class="mb-0"><span class="font-weight-light">Claim</span></h2>
                  <h5 class="mr-auto">{{ claim.no }} / {{ claim.jobid }}</h5>
                </div>
                <!-- action dropdown -->
                <div class="mt-3 mt-sm-0 ml-sm-3 flex-shrink-0">
                  <b-dropdown variant="outline-secondary" class="ml-1 pb-2" size="sm" right text="Actions" aria-label="Order actions toolbar">
                    <b-dropdown-item v-on:click="sendClaim" aria-label="Send claim" :disabled="!userHasAnyOfRoles(['superadmin','puadmin','customerservice'])">Send Claim</b-dropdown-item>
                         <b-dropdown-item  aria-label="Send claim" :href="this.$route.path+'/email'" :disabled="!userHasAnyOfRoles(['superadmin','puadmin','customerservice'])">Email</b-dropdown-item>
                  </b-dropdown>
                </div>

            
              </div>
            </div>
          </div>
        </div>
      </div>

      
      <!-- content -->
      <div class="details-wrapper container bg-site-light py-4 rounded">

        <!-- Intro -->
        <div class="row">
          <div class="col-sm-12">
            <!-- Maybe an intro, especially when we first land here after we create a new claim -->
            <!-- All this needs better logic if it should be used - i.e. module with definitions, texts, colors... -->
            <BaseNotice class="p-3 mb-3" :variant="claim.status == 'rejected' ? 'danger' : (claim.status == 'resolved' ? 'success' : 'purple')">
              <h5 class="mb-0">Claim is {{ claim.status }}</h5>
            </BaseNotice>

            <!--
            <div class="details-intro">
              <div class="d-flex justify-content-between">
                <div class="">
                </div>

                <h2 class="mb-0">
                  <b-icon icon="three-dots" class="rounded-circle bg-primary lg" v-if="status === 'open' || status === 'pending'" ></b-icon>
                  <b-icon icon="check-square" class="lg" variant="success" v-if="status === 'resolved'" ></b-icon>
                  <b-icon icon="x-circle" class="rounded-circle lg" variant="danger" v-if="status === 'rejected'" ></b-icon>
                  <u v-if="userHasAnyOfRoles(['superadmin'])">
                    <b-dropdown :text="status" class="bg-light" variant="light" size="lg" >
                      <b-dropdown-item @click="status = 'rejected'" >rejected</b-dropdown-item >
                      <b-dropdown-item @click="status = 'pending'" >pending</b-dropdown-item >
                      <b-dropdown-item @click="status = 'resolved'" >resolved</b-dropdown-item >
                    </b-dropdown>
                  </u>
                  <u v-else>{{ status }}</u>
                </h2>
              </div>
            </div>
            -->
          </div>
        </div>
        <!-- intro -->

        <!-- real content & boxes etc -->
        <div class="row here-could-be-grid">

          <!-- status table -->
          <div class="col-sm-12 col-lg-4 order-1 order-lg-2">
            <b-card border-variant="light" header="DETAILS" header-border-variant="secondary" header-bg-variant="white" header-text-variant="dark" align="left" class="mb-3">
              <b-card-text>
                <b-table-simple borderless small class="narrow table-responsive">

                  <b-tr>
                    <b-th class="text-right">Status</b-th>
                    <b-td class="text-capitalize">{{ claim.status }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th class="text-right">Created</b-th>
                    <b-td>{{ claim.created_at | moment("Y-MM-DD HH:mm") }}</b-td>
                  </b-tr>
                  <b-tr v-if="userHasAnyOfRoles(['superadmin','puadmin', 'customerservice'])">
                    <b-th class="text-right">Account</b-th>
                    <b-td>{{ claim.account }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th class="text-right">Source</b-th>
                    <b-td>
                      <b-link v-if="claim.job_id" :to="{name: 'JobDetails', params: {job_id: claim.job_id }}">{{ claim.jobid }}</b-link>
                      <span v-else>-</span>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th class="text-right">Replacement</b-th>
                    <b-td>
                      <b-link v-if="claim.replacement_jobid" :to="{name:'JobDetails', params: { job_id: claim.replacement_job_id }}">{{ claim.replacement_jobid }}</b-link>
                      <span v-else>-</span>
                    </b-td>
                  </b-tr>
                  
                  <!-- <template v-if="userHasAnyOfRoles(['superadmin','puadmin', 'customerservice'])">
                    <b-tr>
                      <b-th class="text-right">Requested</b-th>
                      <b-td v-if="claim.claim_created_at">{{ claim.claim_created_at | moment("Y-MM-DD HH:mm") }}</b-td>
                      <b-td v-else>-</b-td>
                    </b-tr>
                    !-- only on claim_created at ? --
                    <template v-if="claim.claim_created_at">
                      <b-tr v-if="claim.claim_accepted_at">
                        <b-th class="text-right">Accepted</b-th>
                        <b-td v-if="claim.claim_accepted_at">{{ claim.claim_accepted_at | moment("Y-MM-DD HH:mm") }}</b-td>
                        <b-td v-else>-</b-td>
                      </b-tr>
                      <b-tr v-else-if="claim.claim_rejected_at">
                        <b-th class="text-right">Rejected</b-th>
                        <b-td>{{ claim.claim_rejected_at | moment("Y-MM-DD HH:mm") }}</b-td>
                      </b-tr>
                      <b-tr v-else>
                        <b-th class="text-right">Accepted</b-th>
                        <b-td>Pending</b-td>
                      </b-tr>
                    </template>
                    <template v-if="claim && claim.shipment && claim.shipment.length">
                      <b-tr>
                        <b-th>Shipments</b-th>
                        <b-td><span v-for="(sm, smkey) in claim.shipment" :key="smkey">{{ sm.speditor }} / {{ sm.service }}</span><br></b-td>
                      </b-tr>
                    </template>
                  </template> -->

                </b-table-simple>

                <!-- send claim button 
                <b-button 
                  v-if="! claim.claim_created_at && userHasAnyOfRoles(['superadmin','puadmin', 'customerservice'])"
                  block
                  variant="danger"
                  @click="sendClaim"
                >Send transporter claim</b-button>
                -->

                <!-- send claim button -->
                <b-button 
                  v-if="userHasAnyOfRoles(['superadmin','puadmin', 'customerservice'])"
                  block
                  :disabled="!!claim.replacement_job_id || (claim.status != 'open' && status != 'pending') || refund "
                  variant="danger"
                  @click="sendClaim('claim')"
                >
                  Create Claim Order
                </b-button>

                <b-button 
                  v-if="userHasAnyOfRoles(['superadmin','puadmin', 'customerservice'])"
                  block
                  :disabled="!!claim.replacement_job_id || (claim.status != 'open' && status != 'pending') || refund "
                  variant="secondary"
                  @click="sendClaim('resend')"
                >
                  Create Resend Order
                </b-button>

              </b-card-text>
            </b-card>


            <b-card v-if="userHasAnyOfRoles(['superadmin','puadmin', 'customerservice'])" v-show="categories.lost_transport || categories.transport_damage" border-variant="light" header="SPEDITOR CLAIM" header-border-variant="secondary" header-bg-variant="white" header-text-variant="dark" align="left" class="mb-3">
              <b-card-text>
                <b-dropdown variant="outline-dark" split :text="selected_speditor_claim_text" class="mb-2">
                  <b-dropdown-item @click="()=>{speditor_claim = sclaim.status}" v-for="sclaim in speditor_claim_arg" :key="sclaim.status">
                    {{sclaim.text}}
                  </b-dropdown-item>
               </b-dropdown>
               <div class="d-flex justify-content-start align-items-center">
                
                 <!-- <span>{{  speditor_claim  }}</span> -->

                 
               </div>
              </b-card-text>
            </b-card>
          
          </div>

          <!-- category box -->
          <div class="col-sm-12 col-lg-8 order-2 order-lg-1">
            <b-card border-variant="light" header="REQUEST" header-border-variant="secondary" header-bg-variant="white" header-text-variant="dark" align="left" class="mb-3" >
              <b-card-text>

                <b-form-group label="Status" label-size="sm" class="mb-3" v-show="userHasAnyOfRoles(['superadmin','puadmin','customerservice'])">
                  <b-form-select 
                    v-model="status" size="sm" 
                    :options="[{value: 'open', text: 'Open'}, {value: 'pending', text: 'Pending'}, {value: 'resolved', text: 'Resolved'}, {value: 'rejected', text: 'Rejected'}, {value: 'on-hold', text: 'On Hold'}]"
                    class="w-50"
                  ></b-form-select>
                </b-form-group>

                <b-form-group label="Reason" label-size="sm" v-slot="{ ariaDescribedby }" class="mb-3" :disabled="!userHasAnyOfRoles(['superadmin','puadmin','customerservice'])" >
                  <b-form-radio-group stacked id="radio-group-2" v-model="categories" :aria-describedby="ariaDescribedby" name="radio-sub-component" >
                    <b-form-radio :value="{ transport_damage: true, lost_transport: false, defect_packaging: false, wrong_packaging: false, }" >Damaged during transport</b-form-radio >
                    <b-form-radio :value="{ transport_damage: false, lost_transport: true, defect_packaging: false, wrong_packaging: false, }" >Lost during transport</b-form-radio >
                    <b-form-radio :value="{ transport_damage: false, lost_transport: false, defect_packaging: true, wrong_packaging: false, }" >Production defects</b-form-radio >
                    <b-form-radio :value="{ transport_damage: false, lost_transport: false, defect_packaging: false, wrong_packaging: true, }" >Wrong content in package</b-form-radio >
                  </b-form-radio-group>
                </b-form-group>

                <!-- refund admin / customer -->
                <b-checkbox v-if="userHasAnyOfRoles(['superadmin','puadmin','customerservice'])"
                  class="" 
                  :disabled="!userHasAnyOfRoles(['superadmin','puadmin', 'customerservice'])||blockRefundLogic" 
                  v-model="refund" 
                  switch
                >Refund</b-checkbox>
                <p v-else class="font-italic mb-0">
                  <span v-if="refund" class="text-danger">Refund is requested!</span>
                  <span v-else class="text-muted">Refund is not requested!</span>
                </p>
                <p class="text-warning" style="font-size:0.8rem;" v-show="blockRefundLogic">
                  An order shipped using Tracked Letter cannot be refunded due to lost or damaged package.
                  </p>


                    <div v-if="claim_text||userHasAnyOfRoles(['superadmin','puadmin', 'customerservice'])">
                    <div class="mt-2 ">Claim description</div>
                      <b-form-textarea
                      class="mt-3 mb-3"
                      id="textarea"
                      v-model="claim_text"
                      :disabled="!userHasAnyOfRoles(['superadmin','puadmin', 'customerservice'])"
                      placeholder="Describe the claim (required)"
                      rows="3"
                      max-rows="6"
                    ></b-form-textarea>
                  </div>

                    <div v-if="response||userHasAnyOfRoles(['superadmin','puadmin', 'customerservice'])">
                    <div class="mt-2 ">Customer service Response</div>
                      <b-form-textarea
                      class="mt-3 mb-3"
                      id="textarea"
                      v-model="response"
                      :disabled="!userHasAnyOfRoles(['superadmin','puadmin', 'customerservice'])"
                      placeholder="Comment from customer service...."
                      rows="3"
                      max-rows="6"
                    ></b-form-textarea>
                  </div>
                   <!-- tags -->
                   <!-- 255, 250, 237  255, 253, 212  237, 252, 255 255, 245, 245  245, 253, 255  255, 251, 245-->
                <div class=" shadow-sm p-3 mb-3" style="background-color:rgb(255, 251, 245 );" v-if="userHasAnyOfRoles(['superadmin','puadmin', 'customerservice'])">
                      <div v-if="tags" >Tags for internal statistics
                        <div class="row ">
                        <div class=" col-lg-6" >
                         <div class="text-danger align-self-center " align-v="center" v-if="!tags.length"><b>Please choose at least one tag</b></div> 
                       
                        <!-- <b-badge variant="" size="" class="m-1 p-2 text-white shadow-sm" pill style="font-size:12px;background-color:rgb(232, 117, 79);" v-for="tag in tags" :key="tag">{{tagText(tag)}}</b-badge> -->
                        <b-badge variant="" size="" class="m-1 p-2 text-white shadow-sm" pill :style="`font-size:12px;background-color:${pillColor(tag.category)};`" v-for="tag in filter_selected_tag" :key="tag.tag">{{ tag.text }}</b-badge>  
                      </div>
                        </div>
                      </div>
                    <div class="d-flex">
                    <b-button variant="info" v-if="userHasAnyOfRoles(['superadmin','puadmin', 'customerservice'])" v-b-modal.tags-modal size="sm" class="mt-2">Change/Set tags</b-button>
                      
                    </div>
                      <b-modal  id="tags-modal" ref="tags-modal" hide-footer title="Tags">
                      <!-- <p class="my-4">Internal classification tags</p> -->
                          <b-form-checkbox-group
                          v-if="tag_data"
                          id="checkbox-group-2"
                          v-model="tags"
                          stacked
                          name="flavour-2"
                        >
                      
                        <div class="mt-4 pb-2 border-bottom">
                          <div class="h5">Transport</div>
                          <b-form-checkbox
                          v-for="tag in tags_transport"
                          :key="tag.tag"
                          :value="tag.tag" 
                          >
                          {{tag.text}}
                        </b-form-checkbox>
                       </div>

                       <div class="mt-4 pb-2 border-bottom">
                        <div class="h5">Internal</div>
                          <b-form-checkbox
                          v-for="tag in tags_internal"
                          :key="tag.tag"
                          :value="tag.tag" 
                          >
                          {{tag.text}}
                        </b-form-checkbox>
                       </div>

                       <div class="mt-4 pb-2 border-bottom">
                        <div class="h5">External</div>
                          <b-form-checkbox
                          v-for="tag in tags_external"
                          :key="tag.tag"
                          :value="tag.tag" 
                          >
                          {{tag.text}}
                        </b-form-checkbox>
                       </div>
                        </b-form-checkbox-group>
                        <div class="row justify-content-end">
                      <b-button class="m-3 col-2" variant="danger" block @click="hideTagModal()">Close</b-button>
                        </div>
                    </b-modal>
                  </div>  
                 <!-- tags -->

                <b-button class="float-right" variant="secondary" size="sm" @click="updateClaim" v-if="userHasAnyOfRoles(['superadmin','puadmin', 'customerservice'])" >Save</b-button>
              </b-card-text>
            </b-card>
          </div><!-- end col -->


          <!-- Items -->
          <div class="col-sm-12 col-lg-8 order-3">
            <b-card border-variant="light" header="DAMAGED UNITS" header-border-variant="secondary" header-bg-variant="white" header-text-variant="dark" align="left" class="mb-3" >
              <b-card-text>
                <DamagedOrders :items="claim.items" />
              </b-card-text>
            </b-card>
          </div>

          <!-- Photos -->
          <div class="col-sm-12 col-lg-4 order-4">
            <b-card border-variant="light" header="PHOTOS" header-border-variant="secondary" header-bg-variant="white" header-text-variant="dark" align="left" class="" >
              <b-card-text>
                <div v-if="! claim.images || ! claim.images.length">
                  <i class="text-muted">No photos provided...</i>
                </div>
                <transition-group name="list" tag="div">
                  <div v-for="(image, index) in claim.images" v-bind:key="image" class="divAlign w-100 mb-2 justify-content-center">
                    <b-img v-bind:src="image" class="imgAlign" @click="$lightIt(image, `Claim photo #${index+1}`)"></b-img>
                    <!--
                    <div class="text-right">
                      <a class="small" :download="`claim_${claim.no}_photo_${index+1}.`+baseExtension(image)" :href="image ">Download image</a>
                    </div>
                    -->
                  </div>
                </transition-group>
              </b-card-text>
            </b-card>
          </div>

        </div>

      </div>

    </div>
    
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import indClaims from "@/apis/Claim.js";
import DamagedOrders from "@/components/DamagedOrders/DamagedOrders.vue";
export default {
  name: "IndividualClaim",
  components: {
    DamagedOrders,
  },
  data() {
    return {
      claim: {},
      img_src: "",
      status: "",
      categories: {},
      updatedClaim: {},
      time_sent: "",
      refund: "",
      category: null,
      created: null,
      accepted: null,
      rejected: null,
      response:'',
      claim_text:'',
      speditor: null,
      service:null,
      tags:[],
      speditor_claim_arg:[
        {text:'Reklameras ej',status:0},
        {text:'Ska reklameras',status:1},
        {text:'Reklamation skickad',status:2},
        {text:'Reklamation avslogs',status:3},
        {text:'Reklamation godkänd',status:4},
        {text:'Reklamation utbetald',status:5},
      ],
      speditor_claim:0,
      tag_data:[
        // {tag:'damagggased-frame',text:'damaged frame'},
        // {tag:'damagaased-frame',text:'damaged frame'},
        // {tag:'damageffadsd-frame',text:'damaged frame'},
        // {tag:'damad-frame',text:'damaged la frame'},
        // {tag:'daged-frame',text:'damaged que frame'},
        // {tag:'dad-frame',text:'damaged paso frame'},
        // {tag:'damafdafd-frame',text:'damaged frame'},
      
      ],
      tags_transport:[],
      tags_external:[],
      tags_internal:[],
     //tagArray:[]
    };
  },
  methods: {
    async getClaim() {
      await this.getTags();
      var response = await indClaims.individualClaim(
        this.$route.params.claim_id
        
      );
      

      this.claim = response.data.claim;
      this.status = this.claim.status;
      this.categories = this.claim.categories;
      this.time_sent = this.claim.created_at;
      this.refund = this.claim.refund;
      this.category = this.claim.categories;
      this.created = this.claim.claim_created_at;
      this.accepted = this.claim.claim_accepted_at;
      this.rejected = this.claim.claim_rejected_at;
      this.response = this.claim.claim_response;
      this.claim_text = this.claim.claim_text;
      this.tags = this.claim.tags||[];
      this.service = this.claim?.shipment[0]?.service;
      this.speditor = this.claim?.shipment[0]?.speditor;
      this.speditor_claim = (this.claim.speditor_claim || this.claim.speditor_claim==0)?this.claim.speditor_claim:0;
  
   

     

    },
    putImage(image) {
      this.img_src = image;
    },
    async updateClaim() {
      await this.assembleUpdateClaim();
      var response = await indClaims.changeIndividualClaim(
        this.claim.id,
        this.updatedClaim
      );
      if ( response.data?.status !== 'ok' ) {
        const message = response.data?.message || 'Error saving error, please try again later.';
        this.$toastIt(message, {variant: 'danger', autoHideDelay: 5000});
      }
      if ( response.data?.status === 'ok' ) {
        const message = response.data?.message || 'Claim updated!';
        this.$toastIt(message, {variant: 'success', autoHideDelay: 2000});
      }
     
    },
    assembleUpdateClaim() {
      this.updatedClaim.claim_id = this.claim.id;
      this.updatedClaim.categories = this.categories;
      this.updatedClaim.status = this.status;
      this.updatedClaim.refund = this.refund;
      this.updatedClaim.response = this.response;
      this.updatedClaim.claim_text = this.claim_text;
      this.updatedClaim.tags = this.tags;
      this.updatedClaim.speditor_claim = this.speditor_claim;
    
    },

    async sendClaim(type) {

      await this.$confirmIt(`Create a ${type} order?`)
      .then( async value => {
        if (value) {
          var response = await indClaims.sendClaim(this.claim.id, type);
                
          if ( response.data?.status == 'ok' ) {
            const message = (type == 'claim' ? 'Claim created successfully' : 'Resend created successfully');
            this.$toastIt(message, {variant: 'success', autoHideDelay: 2000});
          } else {
            let message = type == 'claim' ? 'Could not create claim' : 'Could not create resend';
            message = response.data?.message ?? message;
            this.$toastIt(message, {variant: 'danger', autoHideDelay: 2000});
          }
/*
          this.created = response.data.claim.claim_created_at;
          this.accepted = response.data.claim.claim_accepted_at;
          this.rejected = response.data.claim.claim_rejected_at;
*/
        }
      });
    },

    baseExtension(base64) {
      const regex = /^data:image\/([a-z]+);/;
      let m;
      if ((m = regex.exec(base64)) !== null) {
        return m[1];
      }
      return '.jpg'; // bad
    },
    hideTagModal(){
      //this.$refs['tags-modal'].hide()
 
      this.$refs['tags-modal'].hide()

    },
  async  getTags(){
      var response = await indClaims.getTags();
      this.tag_data = response.data.tags;
      this.tags_transport = this.tag_data.filter(tag=>tag.category === 'transport');
      this.tags_internal = this.tag_data.filter(tag=>tag.category === 'internal');
      this.tags_external = this.tag_data.filter(tag=>tag.category === 'external');
  
      
    },
    tagText(_tag){
      
      var text = this.tag_data.find(t=>{

        return t.tag==_tag
      })
      if(text){
      return text.text
      }

  
    },
    pillColor(_type){
      let color_value = '#FF8A5F'
      switch(_type){
        case 'transport':
          color_value = '#FF8A5F'
          break;
        case 'internal':
          color_value = '#A36953'
          break;        
        case 'external':
          color_value = '#55433C'
          break;
      }
      return color_value;
    },
  },
  props: {
    id: String,
  },
  created() {
    this.getClaim();
    
  },
  mounted() {
    
  },
  watch: {
    refund: function () {
      
    },
    blockRefundLogic: function(){
      if(this.blockRefundLogic){
        this.refund = false;
      }
    }
  },
  computed: {
    ...mapGetters("user", ["userHasRole", "userHasAnyOfRoles", "currentUser"]),
    blockRefundLogic(){
      //transport_damage: true, lost_transport: false, defect_packaging: false, wrong_packaging: false, 
      if(this.speditor == 'PBREV'&&(this.service == 'P34'||this.service == 'PUA')){
      if(this.categories.lost_transport || this.categories.transport_damage){

        return true;
        
      }
      }
      return false;


    },
    filter_selected_tag: function(){
       return this.tag_data.filter(tag=> this.tags.includes(tag.tag))
      
    },
    selected_speditor_claim_text: function (){
     
      return this.speditor_claim_arg.filter(sc=>sc.status === this.speditor_claim)[0].text
    }

  },
};
</script>

<style lang="scss" scoped>
.table.narrow {
  th, td { padding: 0 0.3rem; }
}


.image-container {
  position: relative;
  display: inline-block;

  width: 120px;
  height: 120px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 2% 2%;
}

.border-style {
  border-style: dotted !important;
}

.alignTheText {
  display: flex !important;
  justify-content: center !important; /* align horizontal */
  align-items: center !important; /* align vertical */
}

.divAlign {
  position: relative;
  width: 100%;
  cursor: zoom-in;

  .btnAlign {
    position: absolute;
    top: 2%;
    right: 2%;
  }

 .imgAlign {
  width: 100%;
  height: auto;
  }
}

.list-enter-active,
.list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(30px);
}
</style>