import { render, staticRenderFns } from "./DamagedOrders.vue?vue&type=template&id=249ed8fa&scoped=true&"
import script from "./DamagedOrders.vue?vue&type=script&lang=js&"
export * from "./DamagedOrders.vue?vue&type=script&lang=js&"
import style0 from "./DamagedOrders.vue?vue&type=style&index=0&id=249ed8fa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "249ed8fa",
  null
  
)

export default component.exports